<template>
	<div v-if="productChannels.length > 0" class="d-flex flex-column wrapper flex-fill">
		<div class="d-flex">
			<p class="title">{{ config.product_prefix }}</p>
		</div>
		<div class="grid" :style="gridStyle">
			<grid-element-wrapper v-for="(component, index) in gridElements" :key="index" :component="component" />
		</div>
	</div>
</template>

<script>
import { products } from '../config/config';
/* eslint-disable vue/no-unused-components */

import GridHeader from '../components/grid/GridHeader.vue';
import GridInput from '../components/grid/GridInput.vue';
import GridText from '../components/grid/GridText.vue';
import GridSelect from '../components/grid/GridSelect.vue';
import GridButton from '../components/grid/GridButton.vue';
import GridElementWrapper from '../components/GridElementWrapper.vue';

export default {
	components: {
		GridElementWrapper,
		GridHeader,
		GridInput,
		GridText,
		GridSelect,
		GridButton,
	},
	props: {
		type: String,
	},
	inject: ['loadData',],
	computed: {
		config() {
			return products.find((c) => c.product_type == this.type);
		},
		gridStyle() {
			return { gridTemplateColumns: `repeat(${this.config.channels_data.length + 1}, auto)` };
		},
        productChannels() {

            const data = this.loadData();

            // Generate a list of all the product channels of a type
            const productChannels = [];
            for (const product of data) {
                if (product.type == this.type) {
                    for (const channel of product.channels) {
                        productChannels.push({title: `${product.title} - ${channel.title}`, data: channel.data})
                    }
                }
            }
            return productChannels
        },

		gridElements() {
			const elements = [];

			// Append headers
			// First is always empty, (channels-title)
			elements.push({ vueComponent: GridHeader, args: { text: '' } });

			// Append dynamic headers, dependent on product-config
			for (const channelData of this.config.channels_data) {
				// console.log(channelData);
				elements.push({ vueComponent: GridHeader, args: { text: channelData.title } });
			}

			// Append channels
			for (const channel of this.productChannels) {
				// Append channel title
				elements.push({ vueComponent: GridText, args: { text: channel.title } });

				// Append dynamic input fields
				for (const i in channel.data) {
					if (this.config.channels_data[i].type == 'select') {
						elements.push({
							vueComponent: GridText,
							args: {
								text: channel.data[i],
							},
						});
					} else if (this.config.channels_data[i].type == 'text') {
						elements.push({
							vueComponent: GridText,
							args: {
								text: channel.data[i],
							},
						});
					}
				}
			}

			return elements;
		},
	},
};
</script>

<style scoped>
.grid {
	display: grid;
}

.wrapper {
	background-color: #eeeeee;
	padding: 10px;
	padding-bottom: 20px;
	margin-bottom: 10px;
}

.title {
	font-size: larger;
	margin: 0;
	margin-right: 40px;
	font-weight: bold;
}

.btn-delete {
	justify-self: right;
}
</style>
