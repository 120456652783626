<template>
	<div class="d-flex align-items-center">
		<img
			class="add-logo"
			src="../assets/add.png"
			alt="add"
			@click="
				() => {
					selectedProductType.value = product.product_type;
					addProduct(product.product_type);
					renderSelectedProductType();
				}
			"
		/>
		<p
			class="text"
			:class="{ selected: product.product_type == selectedProductType.value }"
			@click="
				() => {
					selectedProductType.value = product.product_type;
					renderSelectedProductType();
				}
			"
		>
			{{ product.product_prefix }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		product: Object,
	},
	inject: ['selectedProductType', 'renderSelectedProductType', 'addProduct'],
};
</script>

<style scoped>
.add-logo {
	cursor: pointer;
	height: 34px;
	padding: 5px;
	transition: all 0.2s;
	opacity: 0.3;
}

.add-logo:hover {
	opacity: 1;
}

.text {
	cursor: pointer;
	margin: 0;
	padding: 0;
	transition: all 0.2s;
}

.text:hover {
	padding-left: 5px;
}

.selected {
	color: #ee0000;
	padding-left: 5px;
	font-weight: bold;
}
</style>
