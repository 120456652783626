<template>
	<div class="d-flex flex-column w-100 h-100">
		<top-nav></top-nav>
		<router-view></router-view>
	</div>
</template>

<script>
import TopNav from './components/TopNav.vue';

import { products } from './config/config';

export default {
	name: 'App',
	components: { TopNav },
	provide() {
		return {
			saveData: this.saveData,
			loadData: this.loadData,
			postParsedArrayToServerAndGetCSV: this.postParsedArrayToServerAndGetCSV,
			parseDataForPostingToServer: this.parseDataForPostingToServer,
		};
	},
	methods: {
		saveData(data) {
			sessionStorage.data = JSON.stringify(data);
		},
		loadData() {
			if (sessionStorage.data) {
				return JSON.parse(sessionStorage.data)
			} else {
				return []
			}
		},
		parseDataForPostingToServer() {
			const data = this.loadData();

			const parsedArray = [];

			// Loop all added products from SS
			for (const product of data) {
				// Find correct config for product-type
				for (const productConfig of products) {
					if (productConfig.product_type == product.type) {
						// This is the correct config for product
						// Loop all channels from added product
						for (const channel of product.channels) {
							const channel_object_to_append = {
								// Description in ETS consist of product_title and channel_title
								// TODO: Make better removal of unwanted symbols
								product_type: product.type.replace(',', '').replace(';', ''),
								channel_description: `${product.title} - ${channel.title}`.replace(',', '').replace(';', ''),
								channel_title: '',
							};

							// Create title for channel according to product-type-config
							let i;
							for (i = 0; i < productConfig.channels_data.length; i++) {
								if (productConfig.channels_data[i].add_to_ETS_description_field == true) {
									// TODO: Make better removal of unwanted symbols
									channel_object_to_append.channel_title += `${channel.data[i]} - `.replace(',', '').replace(';', '');
								}
							}

							parsedArray.push(channel_object_to_append);
						}
					}
				}
			}

			return parsedArray;
		},
		async postParsedArrayToServerAndGetCSV(_parsed_array, _desired_name_of_csv_file) {
			// Send parsed array to server and await response.
			let response = await fetch('/generate', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ array: _parsed_array, name_of_csv: _desired_name_of_csv_file }),
			});

			if (response.status == 200) {
				// CSV-file was generated on the server, now go get it boyyyyy!
				window.open('/csv');
			} else {
				// Wowow, something went wrong?!
				alert(
					'Something went horrebly wrong on the serverside, the apocalypse is comming. RUN!\n\nPlease inform Brage Svensli/OK'
				);
			}
		},
	},
};
</script>

<style>
#app {
	position: absolute;
	width: 100%;
	height: 100%;
}

#logo {
	width: 90px;
	padding: 5px;
}

* {
	font-family: Arial, sans-serif;
}

input[type='button'] {
	cursor: pointer;
	outline: none;
	border: none;
	background-color: #ee0000;
	color: #ffffff;
}

input[type='button']:hover {
	background-color: #cc0000;
}

input[type='button']:disabled {
	background-color: #767676;
}

.mmn-bg-black {
	background-color: #333;
}

.mmn-color-black {
	color: #333;
}
</style>
