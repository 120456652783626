<template>
	<div class="d-flex justify-content-between align-items-center mmn-bg-black p-1">
		<p class="text-white fs-4 mb-0 mx-3">KNX CSV-generator for ETS</p>
		<p class="link" @click="$router.push({ name: 'Main' })">1. Legg til produkter og info</p>
		<p class="link" @click="$router.push({ name: 'Overview' })">2. Se gjennom</p>
		<p class="link" @click="$router.push({ name: 'Generate' })">3. Lag CSV</p>
		<img id="logo" src="../assets/micro-matic-logo-hvit-rod-rgb.svg" alt="" />
	</div>
</template>

<script>
export default {};
</script>

<style scoped>
    .link {
        margin: 0;
        font-size: 1.25rem;
        color: white;
        cursor: pointer;
    }

    .link:hover {
        text-decoration: underline
    }
</style>
