<template>
	<p class="header">
		{{ args.text }}
	</p>
</template>

<script>
export default {
	props: {
		args: Object,
	},
};
</script>

<style scoped>
    .header {
        margin: 0;
        padding: 3px;
        font-size: small;
        font-weight: bold;
        border-bottom: solid 1px #767676;
    }
</style>
