// Skrevet av Brage Svensli
// For Micro Matic Norge AS @C All rights reserved

// TODO: Get from server..
// Temp storage of products-config, this will come from server in future.
const products = [
	{
		product_type: 'dimmer',
		product_prefix: 'Dimmer ',
		channels_max: 255,
		channels_prefix: 'Utgang ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Taklys',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Type last',
				type: 'text',
				placeholder: 'LED (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Rekkeklemme',
				type: 'text',
				placeholder: 'X-1-11 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Bryter / Plassering',
				type: 'text',
				placeholder: '514200 / Ved dør (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'dali-dimmer',
		product_prefix: 'DALI-dimmer ',
		channels_max: 16,
		channels_prefix: 'Utgang ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Rele og stikk',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Type last',
				type: 'text',
				placeholder: 'LED (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Rekkeklemme',
				type: 'text',
				placeholder: 'X-1-11 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Bryter / Plassering',
				type: 'text',
				placeholder: '514200 / Ved dør (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'rele-lys-og-stikk',
		product_prefix: 'Rele lys og stikk ',
		channels_max: 20,
		channels_prefix: 'Utgang ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Taklys',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Kommentar',
				type: 'text',
				placeholder: 'LED (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Rekkeklemme',
				type: 'text',
				placeholder: 'X-1-11 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Bryter / Plassering',
				type: 'text',
				placeholder: '514200 / Ved dør (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'rele-varme-v',
		product_prefix: 'Termostat: varmesoner ',
		channels_max: 20,
		channels_prefix: 'Utgang ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Varmekabel',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Type termostat',
				type: 'select',
				options: [
					{
						title: '5142',
					},
					{
						title: '5145',
					},
					{
						title: 'Dinuy',
					},
					{
						title: 'MDT',
					},
					{
						title: 'Tavlemontert',
					},
				],
				add_to_ETS_description_field: false,
			},
			{
				title: 'Type føler',
				type: 'select',
				options: [
					{
						title: 'Romføler',
					},
					{
						title: 'Gulvføler',
					},
					{
						title: 'Romføler m/ begrensning 27_C',
					},
					{
						title: 'Romføler m/ begrensning 28_C',
					},
					{
						title: 'Romføler m/ begrensning 29_C',
					},
					{
						title: 'Romføler m/ begrensning 30_C',
					},
				],
				add_to_ETS_description_field: false,
			},
			{
				title: 'Temperatur produkt',
				type: 'select',
				options: [
					{
						title: 'TS3',
					},
					{
						title: 'TS4',
					},
					{
						title: 'Quad T1',
					},
					{
						title: 'Quad T2',
					},
					{
						title: 'Quad T3',
					},
					{
						title: 'Quad T4',
					},
					{
						title: 'Quad T5',
					},
					{
						title: 'Quad T6',
					},
					{
						title: 'Quad T7',
					},
					{
						title: 'Quad T8',
					},
					{
						title: 'Quad T9',
					},
					{
						title: 'Quad T10',
					},
					{
						title: 'Dinuy T1',
					},
					{
						title: 'Dinuy T2',
					},
					{
						title: 'Dinuy T3',
					},
					{
						title: 'Dinuy T4',
					},
					{
						title: 'Dinuy T5',
					},
					{
						title: 'Dinuy T6',
					},
					{
						title: 'Dinuy T7',
					},
					{
						title: 'Dinuy T8',
					},
					{
						title: 'Dinuy T9',
					},
					{
						title: 'Dinuy T10',
					},
				],
				add_to_ETS_description_field: false,
			},
			{
				title: 'Temperatur sensor',
				type: 'select',
				options: [
					{
						title: 'Inngang 1',
					},
					{
						title: 'Inngang 2',
					},
					{
						title: 'Inngang 3',
					},
					{
						title: 'Inngang 4',
					},
					{
						title: 'Inngang 5',
					},
					{
						title: 'Inngang 6',
					},
					{
						title: 'Inngang 7',
					},
					{
						title: 'Inngang 8',
					},
					{
						title: 'Inngang A',
					},
					{
						title: 'Inngang B',
					},
					{
						title: 'Inngang C',
					},
					{
						title: 'Inngang D',
					},
				],
				add_to_ETS_description_field: false,
			},
			{
				title: 'Kommentar',
				type: 'text',
				placeholder: 'Maks 28 grader (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Rekkeklemme',
				type: 'text',
				placeholder: 'X-1-11 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Bryter',
				type: 'text',
				placeholder: 'Ved dør (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'markise',
		product_prefix: 'Markise og persienne ',
		channels_max: 40,
		channels_prefix: 'Utgang ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Markise',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Kjøretid',
				type: 'text',
				placeholder: '52 sekunder (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Sikkerhet',
				type: 'select',
				options: [
					{
						title: 'Vind',
					},
					{
						title: 'Regn',
					},
					{
						title: 'Frost',
					},
				],
				add_to_ETS_description_field: false,
			},
			{
				title: 'Himmelretning',
				type: 'select',
				options: [
					{
						title: 'Nord',
					},
					{
						title: 'Sør',
					},
					{
						title: 'Øst',
					},
					{
						title: 'Vest',
					},
				],
				add_to_ETS_description_field: false,
			},
			{
				title: 'Rekkeklemme',
				type: 'text',
				placeholder: 'X-1-11 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Bryter / Plassering',
				type: 'text',
				placeholder: '514200 / Ved dør (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'Inngang',
		product_prefix: 'Binærinngang ',
		channels_max: 16,
		channels_prefix: 'Inngang ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Pille for magnetkontakt vindu',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Rekkeklemme',
				type: 'text',
				placeholder: 'X-1-11 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Plassering',
				type: 'text',
				placeholder: 'Veggboks under vindu (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'VAV til',
		product_prefix: 'VAV tilluft ',
		channels_max: 1,
		channels_prefix: 'Tilluft ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Tilluft stue',
				add_to_ETS_description_field: true,
			},
			{
				title: 'V-Min',
				type: 'text',
				placeholder: '20 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'V-Max',
				type: 'text',
				placeholder: '80 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'V-nom',
				type: 'text',
				placeholder: ' (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Kommentar',
				type: 'text',
				placeholder: ' (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Plassering',
				type: 'text',
				placeholder: 'I teknisk rom (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'VAV av',
		product_prefix: 'VAV avtrekk ',
		channels_max: 1,
		channels_prefix: 'Avtrekk ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Stue / 1 ETG',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Avtrekk stue',
				add_to_ETS_description_field: true,
			},
			{
				title: 'V-Min',
				type: 'text',
				placeholder: '20 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'V-Max',
				type: 'text',
				placeholder: '80 (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'V-nom',
				type: 'text',
				placeholder: ' (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Kommentar',
				type: 'text',
				placeholder: ' (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Plassering',
				type: 'text',
				placeholder: 'I teknisk rom (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
	{
		product_type: 'div',
		product_prefix: 'Diverse ekstra funksjoner ',
		channels_max: 150,
		channels_prefix: 'Diverse ',
		channels_data: [
			{
				title: 'Rom / Etasje',
				type: 'text',
				placeholder: 'Ute',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Beskrivelse av funksjon',
				type: 'text',
				placeholder: 'Vanning',
				add_to_ETS_description_field: true,
			},
			{
				title: 'Kommentar',
				type: 'text',
				placeholder: ' (slettes)',
				add_to_ETS_description_field: false,
			},
			{
				title: 'Plassering',
				type: 'text',
				placeholder: 'I hagen (slettes)',
				add_to_ETS_description_field: false,
			},
		],
	},
];

const etsConfig = {
	header_sting: 'Main;Middle;Sub;Main;Middle;Sub;Central;Unfiltered;Description;DatapointType;Security\n',
	product_type_configs: [
		{
			product_type: 'dimmer',
			add_all_channels_to: [
				{
					main_CO_id: 2,
					middle_CO_id: 0,
					suffix: 'Av/På',
				},
				{
					main_CO_id: 2,
					middle_CO_id: 1,
					suffix: 'Dim',
				},
				{
					main_CO_id: 2,
					middle_CO_id: 2,
					suffix: 'Verdi',
				},
				{
					main_CO_id: 2,
					middle_CO_id: 3,
					suffix: 'Tilb av/på',
				},
				{
					main_CO_id: 2,
					middle_CO_id: 4,
					suffix: 'Tilb verdi',
				},
			],
		},
		{
			product_type: 'dali-dimmer',
			add_all_channels_to: [
				{
					main_CO_id: 3,
					middle_CO_id: 0,
					suffix: 'Av/På lys',
				},
				{
					main_CO_id: 3,
					middle_CO_id: 1,
					suffix: 'Dim lys',
				},
				{
					main_CO_id: 3,
					middle_CO_id: 2,
					suffix: 'Verdi lys',
				},
				{
					main_CO_id: 3,
					middle_CO_id: 3,
					suffix: 'Tilb av/på',
				},
				{
					main_CO_id: 3,
					middle_CO_id: 4,
					suffix: 'Tilb verdi',
				},
				{
					main_CO_id: 4,
					middle_CO_id: 1,
					suffix: 'Dim TW',
				},
				{
					main_CO_id: 4,
					middle_CO_id: 2,
					suffix: 'Verdi TW',
				},
				{
					main_CO_id: 4,
					middle_CO_id: 3,
					suffix: 'Tilb verdi TW',
				},
			],
		},
		{
			product_type: 'rele-lys-og-stikk',
			add_all_channels_to: [
				{
					main_CO_id: 5,
					middle_CO_id: 0,
					suffix: 'Av/På',
				},
				{
					main_CO_id: 5,
					middle_CO_id: 1,
					suffix: 'Tilb av/på',
				},
			],
		},
		{
			product_type: 'rele-varme-v',
			add_all_channels_to: [
				{
					main_CO_id: 6,
					middle_CO_id: 0,
					suffix: 'Av/På',
				},
				{
					main_CO_id: 6,
					middle_CO_id: 1,
					suffix: 'Tilb av/på',
				},
				{
					main_CO_id: 6,
					middle_CO_id: 2,
					suffix: 'Kjøling',
				},
				{
					main_CO_id: 6,
					middle_CO_id: 3,
					suffix: 'Tilb kjøling',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 0,
					suffix: 'Er temperatur',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 1,
					suffix: 'Ønsket temperatur',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 2,
					suffix: 'Sett temperatur',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 3,
					suffix: 'Vinduskontakt',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 4,
					suffix: 'Modus Endring',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 5,
					suffix: 'RTC Modus',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 6,
					suffix: 'Ønsket +/- temperatur',
				},
				{
					main_CO_id: 7,
					middle_CO_id: 7,
					suffix: 'Tilb +/- ønsket temperatur',
				},
				{
					main_CO_id: 8,
					middle_CO_id: 1,
					suffix: 'Avlest romføler',
				},
				{
					main_CO_id: 8,
					middle_CO_id: 2,
					suffix: 'Avlest gulvføler',
				},
				{
					main_CO_id: 8,
					middle_CO_id: 3,
					suffix: 'Tilb varme',
				},
				{
					main_CO_id: 8,
					middle_CO_id: 4,
					suffix: 'Party modus',
				},
				{
					main_CO_id: 8,
					middle_CO_id: 5,
					suffix: 'Tilb kjøling',
				},
			],
		},
		{
			product_type: 'markise',
			add_all_channels_to: [
				{
					main_CO_id: 9,
					middle_CO_id: 1,
					suffix: 'Lang tid',
				},
				{
					main_CO_id: 9,
					middle_CO_id: 2,
					suffix: 'Kort tid',
				},
				{
					main_CO_id: 9,
					middle_CO_id: 3,
					suffix: 'Posisjon',
				},
				{
					main_CO_id: 9,
					middle_CO_id: 4,
					suffix: 'Tilb posisjon',
				},
				{
					main_CO_id: 9,
					middle_CO_id: 5,
					suffix: 'Posisjon lameller',
				},
				{
					main_CO_id: 9,
					middle_CO_id: 6,
					suffix: 'Tilb posisjon lameller',
				},
			],
		},
		{
			product_type: 'div',
			add_all_channels_to: [
				{
					main_CO_id: 11,
					middle_CO_id: 1,
					suffix: 'Fri',
				},
				{
					main_CO_id: 11,
					middle_CO_id: 2,
					suffix: 'Tilb fri',
				},
			],
		},
		{
			product_type: 'VAV til',
			add_all_channels_to: [
				{
					main_CO_id: 12,
					middle_CO_id: 0,
					suffix: 'Pådrag VAV',
				},
				{
					main_CO_id: 12,
					middle_CO_id: 1,
					suffix: 'Vmin',
				},
				{
					main_CO_id: 12,
					middle_CO_id: 2,
					suffix: 'Vmax',
				},
				{
					main_CO_id: 12,
					middle_CO_id: 3,
					suffix: 'Balancing mode',
				},
				{
					main_CO_id: 12,
					middle_CO_id: 4,
					suffix: 'Adaption',
				},
				{
					main_CO_id: 12,
					middle_CO_id: 5,
					suffix: 'Override',
				},
				{
					main_CO_id: 12,
					middle_CO_id: 6,
					suffix: 'Hente feilstatus',
				},
				{
					main_CO_id: 13,
					middle_CO_id: 0,
					suffix: 'Luftmengde m3',
				},
				{
					main_CO_id: 13,
					middle_CO_id: 1,
					suffix: 'Spjeld posisjon',
				},
				{
					main_CO_id: 13,
					middle_CO_id: 2,
					suffix: 'Vnom',
				},
				{
					main_CO_id: 13,
					middle_CO_id: 3,
					suffix: 'Co2',
				},
				{
					main_CO_id: 13,
					middle_CO_id: 4,
					suffix: 'Feilstatus',
				},
			],
		},
		{
			product_type: 'VAV av',
			add_all_channels_to: [
				{
					main_CO_id: 14,
					middle_CO_id: 0,
					suffix: 'Reserve Vav',
				},
				{
					main_CO_id: 14,
					middle_CO_id: 1,
					suffix: 'Vmin',
				},
				{
					main_CO_id: 14,
					middle_CO_id: 2,
					suffix: 'Vmax',
				},
				{
					main_CO_id: 14,
					middle_CO_id: 3,
					suffix: 'Balancing mode',
				},
				{
					main_CO_id: 14,
					middle_CO_id: 4,
					suffix: 'Adaption',
				},
				{
					main_CO_id: 14,
					middle_CO_id: 5,
					suffix: 'Override',
				},
				{
					main_CO_id: 14,
					middle_CO_id: 6,
					suffix: 'Hente feilstatus',
				},
				{
					main_CO_id: 15,
					middle_CO_id: 0,
					suffix: 'Luftmengde m3',
				},
				{
					main_CO_id: 15,
					middle_CO_id: 1,
					suffix: 'Spjeld posisjon',
				},
				{
					main_CO_id: 15,
					middle_CO_id: 2,
					suffix: 'Vnom',
				},
				{
					main_CO_id: 15,
					middle_CO_id: 3,
					suffix: 'Co2',
				},
				{
					main_CO_id: 15,
					middle_CO_id: 4,
					suffix: 'Feilstatus',
				},
			],
		},
		{
			product_type: 'Inngang',
			add_all_channels_to: [
				{
					main_CO_id: 1,
					middle_CO_id: 6,
					suffix: 'Inngang',
				},
			],
		},
	],
};

export { products, etsConfig };
