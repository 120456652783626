import { createWebHistory, createRouter } from "vue-router";


import Main from "@/views/Main";
import Overview from "@/views/Overview";
import Generate from "@/views/Generate";

const routes = [
	
	{ name: "Main", path: "/", component: Main },
	{ name: "Overview", path: "/overview", component: Overview },
	{ name: "Generate", path: "/download", component: Generate },
	
];

const router = createRouter({
	history: createWebHistory(process.env.Base_URL),
	routes,
});

export default router;
