<template>
	<div class="d-flex flex-column flex-fill justify-content-center">
		<input type="button" value="Trykk her for print" @click="print" />

		<product-summary v-for="(product, i) in products" :key="i" :type="product.product_type" />
	</div>
</template>

<script>
import ProductSummary from '../components/ProductSummary.vue';
import { products } from '../config/config';

export default {
	components: {
		ProductSummary,
	},
	data() {
		return {
			products,
		};
	},
	methods: {
		print() {
			window.print();
		},
	},
};
</script>

<style scoped></style>
