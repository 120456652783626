<template>
	<div class="d-flex flex-column wrapper">
		<div class="d-flex">
			<p class="title">{{ product.title }}</p>
			<input
				type="button"
				value="Legg til utgang"
				@click="
					() => {
						addChannel(product.title, product.type);
					}
				"
			/>
			<p class="m-0 p-1"> <span class="fw-bold">OBS!</span> Legg inn alle utganger på valgt produkt</p>
			<div class="flex-fill"></div>
			<input
				type="button"
				class="btn-delete"
				value="Slett"
				@click="
					() => {
						removeProduct(product.title, product.type);
					}
				"
			/>
		</div>
		<div class="grid" :style="gridStyle">
			<grid-element-wrapper v-for="(component, index) in gridElements" :key="index" :component="component" />
		</div>
	</div>
</template>

<script>
import { products } from '../config/config';
/* eslint-disable vue/no-unused-components */

import GridHeader from '../components/grid/GridHeader.vue';
import GridInput from '../components/grid/GridInput.vue';
import GridSelect from '../components/grid/GridSelect.vue';
import GridButton from '../components/grid/GridButton.vue';
import GridElementWrapper from '../components/GridElementWrapper.vue';

export default {
	components: {
		GridElementWrapper,
		GridHeader,
		GridInput,
		GridSelect,
		GridButton,
	},
	props: {
		product: Object,
	},
	inject: ['addChannel', 'removeProduct', 'removeChannel'],
	computed: {
		config() {
			return products.find((c) => c.product_type == this.product.type);
		},
		gridStyle() {
			return { gridTemplateColumns: `repeat(${this.config.channels_data.length + 2}, auto)` };
		},
		gridElements() {
			const elements = [];

			// Append headers
			// First is always empty, (channels-title)
			elements.push({ vueComponent: GridHeader, args: { text: '' } });

			// Append dynamic headers, dependent on product-config
			for (const channelData of this.config.channels_data) {
				// console.log(channelData);
				elements.push({ vueComponent: GridHeader, args: { text: channelData.title } });
			}
			// Last header is always empty (remove-btn)
			elements.push({ vueComponent: GridHeader, args: { text: '' } });

			// Append channels
			for (const channel of this.product.channels) {
				// Append channel title
				elements.push({ vueComponent: GridHeader, args: { text: channel.title } });

				// Append dynamic input fields
				for (const i in channel.data) {
					if (this.config.channels_data[i].type == 'select') {
						elements.push({
							vueComponent: GridSelect,
							args: {
                                selected: channel.data[i],
								options: this.config.channels_data[i].options,
								productTitle: this.product.title,
								channelTitle: channel.title,
								channelDataIndex: i,
							},
						});
					} else if (this.config.channels_data[i].type == 'text') {
						elements.push({
							vueComponent: GridInput,
							args: {
								text: channel.data[i],
								placeholder: this.config.channels_data[i].placeholder,
								productTitle: this.product.title,
								channelTitle: channel.title,
								channelDataIndex: i,
							},
						});
					}
				}

				// Append delete btn
				elements.push({
					vueComponent: GridButton,
					args: { text: 'Fjern', productTitle: this.product.title, channelTitle: channel.title, type: this.product.type },
				});
			}

			return elements;
		},
	},
};
</script>

<style scoped>
.grid {
	display: grid;
}

.wrapper {
	background-color: #eeeeee;
	padding: 10px;
	padding-bottom: 20px;
	margin-bottom: 10px;
}

.title {
	font-size: larger;
	margin: 0;
	margin-right: 40px;
	font-weight: bold;
}

.btn-delete {
	justify-self: right;
}
</style>
