<template>
    <select class="select" :value="args.selected" @change="(event) => {updateChannelData(args.productTitle, args.channelTitle, args.channelDataIndex, event.target.value)}">
        <option v-for="option in args.options" :key="option" :value="option.title">{{option.title}}</option>
    </select>
</template>

<script>
export default {
    props: {
        args: Object
    },
    inject: ['updateChannelData']
}
</script>

<style scoped>
    .select {
        outline: none;
        border: none;
        border-bottom: solid 1px #767676;
        border-right: solid 1px #cccccc;
    }
</style>