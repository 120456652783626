<template>
	<input
		type="button"
		:value="args.text"
		@click="
			() => {
				removeChannel(args.productTitle, args.channelTitle, args.type);
			}
		"
	/>
</template>

<script>
export default {
	props: {
		args: Object,
	},
	inject: ['removeChannel'],
};
</script>
