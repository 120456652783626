<template>
	<div class="d-flex flex-fill flex-column justify-content-center align-items-center gap-4">
		<div>
			<input type="button" value="Last ned CSV" class="p-3" @click="getThatSweetCsv" />
		</div>
		<p>Besøk vår nye nettbutikk for å bli inspirert!</p>
	</div>
</template>

<script>
export default {
	inject: ['data', 'postParsedArrayToServerAndGetCSV', 'parseDataForPostingToServer'],
	methods: {
		async getThatSweetCsv() {
			let password = prompt('Passord?')
			if (password != 'funkbus') return
			let name = prompt('Ønsket navn på fil: ');
			await this.postParsedArrayToServerAndGetCSV(this.parseDataForPostingToServer(), name);
		},
	},
};
</script>

<style scoped></style>
