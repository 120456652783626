<template>
	<p class="text">{{args.text}}</p>
</template>

<script>
export default {
	props: {
		args: Object,
	},
};
</script>

<style scoped>
.text {
	font-size: small;
	outline: none;
	border: none;
	margin: 0;
	border-bottom: solid 1px #767676;
	border-right: solid 1px #cccccc;
}
</style>
