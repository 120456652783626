<template>
	<input
		class="input"
		type="text"
		:value="args.text"
		:placeholder="args.placeholder"
		@input="(event) => {updateChannelData(args.productTitle, args.channelTitle, args.channelDataIndex, event.target.value)}"
	/>
</template>

<script>
export default {
	props: {
		args: Object,
	},
	inject: ['updateChannelData'],
};
</script>

<style scoped>
.input {
	font-size: small;
	outline: none;
	border: none;
	border-bottom: solid 1px #767676;
	border-right: solid 1px #cccccc;
}

::placeholder { 
  color: rgb(221, 221, 221);
  font-weight: lighter;
  opacity: 1; /* Firefox */
}

</style>
