<template>
	<div class="d-flex flex-fill">
		<div class="p-3 d-flex flex-column gap-2" style="width: 250px; background-color: lightgrey">
			<input
				type="file"
				@change="
					(event) => {
						files = event.target.files;
					}
				"
				accept=".csv"
			/>

			<input
				type="button"
				@click="parseCsv"
				:value="files.length == 0 ? 'Velg en fil først' : 'Last opp'"
				:disabled="files.length == 0"
			/>

			<select-and-add
				v-for="product in products"
				:key="product.product_type"
				:product="product"
				:onSelect="renderSelectedProductType"
				:onAdd="addProduct"
			></select-and-add>
		</div>

		<div class="flex-fill p-4">
			<p v-if="selectedProductType.value == ''" class="fs-2">Velg eller legg til et produkt fra feltet til venstre</p>
			<p v-if="selectedProductType.value == 'opplastet'" class="fs-2">Filen er lastet opp</p>
			<p v-if="selectedProductType.value != '' && selectedProducts.value.length == 0" class="fs-2">
				Velg eller legg til et produkt fra feltet til venstre
			</p>
			<div class="d-flex flex-column">
				<product-card v-for="product in selectedProducts.value" :key="product.title" :product="product" />
			</div>
		</div>
	</div>
</template>

<script>
import SelectAndAdd from '../components/SelectAndAdd.vue';
import ProductCard from '../components/ProductCard.vue';

import { products, etsConfig } from '../config/config';

export default {
	components: { SelectAndAdd, ProductCard },
	data() {
		return {
			files: [],
			products,
			selectedProductType: { value: '' },
			selectedProducts: { value: [] },

			data: { value: [] },
		};
	},
	inject: ['saveData', 'loadData'],
	provide() {
		return {
			selectedProductType: this.selectedProductType,
			renderSelectedProductType: this.renderSelectedProductType,
			addProduct: this.addProduct,
			addChannel: this.addChannel,
			removeProduct: this.removeProduct,
			removeChannel: this.removeChannel,
			updateChannelData: this.updateChannelData,
		};
	},
	methods: {
		readFile(file) {
			return new Promise((resolve, reject) => {
				var fr = new FileReader();
				fr.onload = () => {
					resolve(fr.result);
				};
				fr.onerror = reject;
				fr.readAsText(file, 'latin1');
			});
		},
		async parseCsv() {

			this.selectedProductType.value = 'opplastet'

			const file = await this.readFile(this.files[0]);
			// console.log(file);

			const output = {};
			let currentMainID, currentMiddleID, currentSubID;

			// Loop all lines, to build an object of the parsed csv
			for (const line of file.split('\n')) {
				// Split line
				const attr = line.split(';');

				const mainTitle = attr[0];
				const middleTitle = attr[1];
				const subTitle = attr[2];
				const mainID = attr[3];
				const middleID = attr[4];
				const subID = attr[5];
				const description = attr[8];


				// Parse the current line
				if (mainTitle != '') {
					// Add new main area
					//console.log(`Main: ${mainID} / ${mainTitle}`);
					currentMainID = mainID;
					output[currentMainID] = { title: mainTitle };
				} else if (middleTitle != '') {
					// Add new middle area to current main
					//console.log(`\tMiddle: ${middleID} / ${middleTitle}`);

					currentMiddleID = middleID;
					output[currentMainID][currentMiddleID] = { title: middleTitle };
				} else if (subTitle != '') {
					// Add new sub area to current middel
					//console.log(`\t\tSub: ${subID} >>> ${subTitle}`);
					currentSubID = subID;
					output[currentMainID][currentMiddleID][currentSubID] = { title: subTitle, description: description };
				}
			}

			// Now parse the object agains the ETS-config(products) to get the final ss object

			const ssObject = [];

			for (const etsProduct of etsConfig.product_type_configs) {
				const channelToCheck = etsProduct.add_all_channels_to[0];
				const checkMainID = channelToCheck.main_CO_id;
				const checkMiddleID = channelToCheck.middle_CO_id;

				// Find coresponding product in products-config
				const configProduct = products.find((product) => product.product_type == etsProduct.product_type);

				// Create a filtered list of channel data that gets added to description
				// const channelDataToDescriptin = configProduct.channels_data.filter((field) => field.add_to_ETS_description_field == true);

				// Loop all sub of current middle

				console.log(checkMainID, checkMiddleID, output);

				for (const sub in output[checkMainID][checkMiddleID]) {
					if (sub != 'title') {
						// Parse every product-channel
						const channelDescriptionAttr = output[checkMainID][checkMiddleID][sub].description.split(' - ');
						const channelTitleAttr = output[checkMainID][checkMiddleID][sub].title.split(' - ');
						const productTitle = channelDescriptionAttr[0];
						const channelTitle = channelDescriptionAttr[1];

						// Check/create product/channel in ssObject
						const ssProduct = ssObject.find((product) => product.title == productTitle);
						if (ssProduct != undefined) {
							// Alreaddy exists

							// Append new channel
							ssProduct.channels.push({ title: channelTitle, data: [] });
							//Append channel data fields //*The generation of the data-points depend on add_to_ETS_description_field, so only read data-points where it is true.
							for (let i = 0; i < configProduct.channels_data.length; i++) {
								if (configProduct.channels_data[i].add_to_ETS_description_field == true) {
									ssProduct.channels.at(-1).data.push(channelTitleAttr[1 + i]);
								} else {
									ssProduct.channels.at(-1).data.push('');
								}
							}
						} else {
							const objectToPush = {
								title: productTitle,
								type: etsProduct.product_type,
								channels: [{ title: channelTitle, data: [] }],
							};
							//Append channel data fields //*The generation of the data-points depend on add_to_ETS_description_field, so only read data-points where it is true.
							for (let i = 0; i < configProduct.channels_data.length; i++) {
								if (configProduct.channels_data[i].add_to_ETS_description_field == true) {
									objectToPush.channels.at(-1).data.push(channelTitleAttr[1 + i]);
								} else {
									objectToPush.channels.at(-1).data.push('');
								}
							}

							ssObject.push(objectToPush);
						}
					}
				}
			}
			// console.log(ssObject);
			this.saveData(ssObject);
			this.renderSelectedProductType();
		},
		renderSelectedProductType() {
			const data = this.loadData();
			this.selectedProducts.value = data.filter((productObject) => productObject.type == this.selectedProductType.value);
		},
		addProduct(type) {
			const data = this.loadData();
			// Find running count of product.
			let productCount = 0;
			// let i;
			for (const product of data) {
				if (product.type == type) {
					productCount++;
				}
			}

			// Push new product
			const object = {
				title: `${this.config.product_prefix}${productCount + 1}`,
				type: this.config.product_type,
				channels: [],
			};

			data.push(object);
			this.saveData(data);

			// Hotfix for edge-case, add 16 if dali, else add one channel
			if (type == 'dali-dimmer') {
				for (let i = 0; i < 16; i++) {
					this.addChannel(object.title, type);
				}
			} else {
				this.addChannel(object.title, type);
			}
		},
		addChannel(productTitle, type) {
			const data = this.loadData();

			for (const product of data) {
				if (product.title == productTitle) {
					if (product.channels.length != this.config.channels_max) {
						// Add channel!
						let channel;

						// Edgecase for product-type: markise
						// TODO: Put this edcase in config..
						if (type == 'markise') {
							channel = {
								title: `${this.config.channels_prefix}${product.channels.length * 2 + 1} og ${product.channels.length * 2 + 2}`,
								data: [],
							};
							// Append empty data to channel data, ref config.
							for (let i = 0; i < this.config.channels_data.length; i++) {
								channel.data.push('');
							}
						} else {
							channel = {
								title: `${this.config.channels_prefix}${product.channels.length + 1}`,
								data: [],
							};
							// Append empty data to channel data, ref config.

							for (let i = 0; i < this.config.channels_data.length; i++) {
								channel.data.push('');
							}
						}

						product.channels.push(channel);
					} else {
						// Max channels added!
						alert(`Max channels for this product is ${this.config.channels_max}.\n\n Defined in productconfig.`);
					}
					break;
				}
			}

			this.saveData(data);
			this.renderSelectedProductType();
		},
		removeProduct(productTitle, type) {
			let data = this.loadData();

			if (!confirm(`Sikker på at du vil slette ${productTitle} ?`)) {
				// User pressed 'NO' - do nothing!
				return;
			}

			// Remove product
			data = data.filter((product) => product.title != productTitle);

			// Need to parse the list again to fix the numbering of the products..
			let productCount = 0;

			data = data.map((product) => {
				if (product.type == type) {
					productCount++;
					console.log(product);
					product.title = `${this.config.product_prefix}${productCount}`;
				}
				return product;
			});
			this.saveData(data);
			this.renderSelectedProductType();
		},
		removeChannel(productTitle, channelTitle, type) {
			const data = this.loadData();
			// Hotfix for edge-case
			if (type == 'dali-dimmer') {
				alert('Kan ikke fjerne kanaler for dette produktet!');
				return;
			}

			for (const product of data) {
				if (product.title == productTitle) {
					product.channels = product.channels.filter((channel) => channel.title != channelTitle);

					// Fix numbering
					let channelCount = 0;
					for (const channel of product.channels) {
						// TODO: Put this edcase in config..
						if (type == 'markise') {
							channel.title = `${this.config.channels_prefix}${channelCount * 2 + 1} og ${channelCount * 2 + 2}`;
						} else {
							channel.title = `${this.config.channels_prefix}${channelCount + 1}`;
						}

						channelCount++;
					}
					break;
				}
			}

			this.saveData(data);
			this.renderSelectedProductType();
		},
		updateChannelData(productTitle, channelTitle, channelDataIndex, value) {
			const data = this.loadData();

			// Update correct channel data
			for (const product of data) {
				if (product.title == productTitle) {
					for (const channel of product.channels) {
						if (channel.title == channelTitle) {
							channel.data[channelDataIndex] = value;
							break;
						}
					}
					break;
				}
			}

			this.saveData(data);
		},
	},
	computed: {
		config() {
			return products.find((c) => c.product_type == this.selectedProductType.value);
		},
	},
};
</script>

<style scoped></style>
