<template>
	<component :is="shallow" :args="component.args"> </component>
</template>

<script>
import { shallowRef } from 'vue';

export default {
	props: {
		component: Object,
	},
	data() {
		return {
			shallow: shallowRef({ ...this.component.vueComponent }),
		};
	},
};
</script>
